.profileSection {
    justify-content: center;
    align-items: center;
    padding: 1em;
    flex-wrap: wrap;
}

.profilePic {
    border: 2px solid var(--mainColor);
    width: 150px;
    aspect-ratio: 1;
    border-radius: 50%;
}

.profilePic>img {
    display: block;
    max-width: 89%;
    border: 4px solid var(--white);
    border-radius: 50%;
    transform: rotate(350deg);
}

.profileDetails {
    flex-direction: column;
    margin-inline: 2em;
    justify-content: start;
    height: 100%;
    align-items: start;
}

.profileName {
    font-size: 35px;
    font-weight: 500;
    text-wrap: nowrap;
}

.profileDesignation {
    font-size: 20px;
    font-weight: 500;
    text-wrap: nowrap;
}

.profileLinks {
    height: 40px;
    aspect-ratio: 1;
}