.skillsList {
    grid-template-columns: repeat(3, 1fr) !important;
}

.svg-name {
    font-size: 20px;
    font-weight: 500;
}

@media (max-width: 760px) {
    .skillsList {
        grid-template-columns: repeat(2, 1fr) !important;
    }
}

@media (max-width: 475px) {
    .skillsList {
        grid-template-columns: repeat(1, 1fr) !important;
    }
}