.tabContainer {
    flex-wrap: wrap;
    padding: 10px;
    gap: 1rem;
    border-radius: 10px;
}

.tabBtn {
    width: 220px;
    height: 60px;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
}

.tabBtn:hover {
    cursor: pointer;
}

.active[id='light'] {
    background-color: var(--activeLightColor) !important;
}

.active[id='dark'] {
    background-color: var(--darkColor) !important;
}