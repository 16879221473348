.outerCirle {
    margin-top: 10px;
    width: 3rem;
    aspect-ratio: 1;
    border-radius: 50%;
}

.modeButton {
    border: 0;
    z-index: 99999;
    height: 1.7rem;
    aspect-ratio: 1;
    background-repeat: no-repeat;
}

.outerCirle:hover,
.modeButton:hover {
    cursor: pointer;
}