.card {
    width: 362px;
    height: 226px;
}

.card:nth-last-child(1) {
    margin-bottom: 20px;
}

.image {
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
}

.image img {

    width: 100%;
    height: 100%;
    object-fit: cover;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: rgba(247, 208, 57, 0.8);
    flex-direction: column;
    gap: 0.7rem;
}

.overlay:hover {
    opacity: 1;
    cursor: pointer;
    transition: 0.2s ease-in;
}

.overlay>.link {
    height: 30px;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: var(--white);
}

.overlay>.heading {
    font-size: 20px;
    font-weight: 700;
}

.overlay>.description {
    font-size: 17px;
    font-weight: 500;
}


@media (max-width:760px) {
    .card {
        width: 90vw;
    }
}

/* animtaion */
/* Odd card selector */
.card:nth-child(2n+1) {
    animation: auto linear slide-from-left both;
    animation-timeline: view(block 100% 10%) !important;
    animation-range: entry 25% cover 50%;
}

/* Even card selector */
.card:nth-child(2n) {
    animation: auto linear slide-from-right both;
    animation-timeline: view(block 100% 10%) !important;
    animation-range: entry 25% cover 50%;
}

@keyframes slide-from-left {
    from {
        opacity: 0;
        transform: translateX(-70%);
        filter: blur(5px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
        filter: blur(0);
    }
}

@keyframes slide-from-right {
    from {
        opacity: 0;
        transform: translateX(70%);
        filter: blur(5px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
        filter: blur(0);
    }
}