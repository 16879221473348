.buttonContainer {
    margin-block: 10px;
    flex-wrap: wrap;
    gap: 1rem;
}

.link {
    text-decoration: none;
}

.button {
    width: 210px;
    height: 50px;
    border: none;
    border-radius: 10px;
    gap: 0.3rem;
}

.button:hover {
    cursor: pointer;
}

.button span {
    font-size: 16px;
    font-weight: 500;

}