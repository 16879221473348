:root {
    box-sizing: border-box;
    --darkColor: #0C151D;
    --lightColor: #E9EBEC;
    --white: #FFFFFF;
    --hoverDark: #171F26;
    --mainColor: #F7D039;
    --boldColor: #3D3D3D;
    --semiboldColor: #575757;
    --darkModeBoldColor: #F1F2F4;
    --darkModeSemiboldColor: #A3ABB2;
    --activeLightColor: #D7D7D7;
}

body {
    margin: 0px;
    padding: 0px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    cursor: url('./assets/svgs/cursor.svg'), default;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--gap, 0rem 1rem);
}

.grid {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
}

@media (max-width:760px) {
    .grid {
        grid-template-columns: repeat(1, 1fr);
    }
}

.darkBoldColor {
    color: var(--darkModeBoldColor)
}

.lightBoldColor {
    color: var(--boldColor)
}

.darkSemiboldColor {
    color: var(--darkModeSemiboldColor)
}

.lightSemiboldColor {
    color: var(--semiboldColor)
}

.mainBgColor {
    background-color: var(--mainColor);
}

::-webkit-scrollbar {
    width: 5px;

}


::-webkit-scrollbar-track {
    background-color: var(--white);
}

::-webkit-scrollbar-thumb {
    background-color: var(--mainColor);

}