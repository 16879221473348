.description {
    flex-direction: column;
    flex-wrap: wrap;
}

.description .number {
    font-size: 22px;
}

.description .detail {
    font-size: 18px;
    max-width: 150px;
}